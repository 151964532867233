import moment from "moment";
import { useAppsStore } from "@/stores/app";
import { mapState } from "pinia";
String.prototype.ArtoEn = function () {
  return this.replace(/[\u0660-\u0669]/g, (d) => d.charCodeAt() - 1632);
};

String.prototype.AMPM = function () {
  return this.replace("ص", "صباحاً").replace("م", "مساءً");
};

const formatDate = (date, format = "YYYY/MM/DD HH:mm:ss") => {
  return moment(date).format(format).ArtoEn().AMPM();
};
export default {
  data() {
    return {
      allApiErrors: {},
    };
  },

  computed: {
    ...mapState(useAppsStore, ["getErrors"]),
  },
  watch: {
    getErrors: {
      deep: true,
      immediate: true,
      handler(newVal) {
        let ErrorObject = JSON.parse(JSON.stringify(newVal));
        if (ErrorObject) {
          this.allApiErrors = {};
          Object.keys(ErrorObject).forEach((key) => {
            this.allApiErrors[key] = ErrorObject[key][0]
              ? ErrorObject[key][0]
              : null;
          });
        } else {
          this.allApiErrors = {};
        }
      },
    },
  },
  methods: {
    formatNumber(number, is_price = true, currancy = "sr") {
      let value = Intl.NumberFormat("en-IN", {
        maximumSignificantDigits: 3,
      }).format(number);
      if (is_price) {
        return value + " " + this.$t("common." + currancy);
      }
      return value;
    },
    formatDate,
  },
};

window.mapFilterData = function mapFilters(data) {
  let filters = "?";

  for (const [key, value] of Object.entries(data)) {
    if (value) {
      filters += `${key}=${value}&`;
    }
  }

  return filters.slice(0, -1);
};
