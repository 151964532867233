const permissions= {


  readDashboard: "read dashboard",

  readMatches: "read matches",
  addMatches: "add matches",
  editMatches: "edit matches",

  readChampionships: "read championships",
  addChampionships: "add championships",
  editChampionships: "edit championships",

  readTeams: "read teams",
  addTeams: "add teams",
  editTeams: "edit teams",

  readUsersInfo: "read users",
  editUsers: "edit users",
  deleteUsers: "delete users",

  readWinner: "read winners",
  addWinner: "add winners",


  readSubscribers: "read subscribers",
  editSubscribers: "edit subscribers",

  readGroups: "read groups",
  editGroups: "edit groups",


  readAds: "read ads",
  addAds: "add ads",
  editAds: "edit ads",
  deleteAds: "delete ads",

  readNotifications: "read notifications",
  sendNotifications: "send notifications",
  deleteNotifications: "delete notifications",

  readPlatform: "read platform",
  editPlatform: "edit platform",

  readPlatformPages: "read platform pages",
  editPlatformPages: "edit platform pages",

  readSubscriptions: "read subscriptions",
  editSubscriptions: "edit subscriptions",

  readPlatformFaq: "read platform faq",

  editPlatformFaq: "edit platform faq",
  createPlatformFaq: "add platform faq",
  deletePlatformFaq: "delete platform faq",

  readPlatformPoints: "read platform points",

  editPlatformPoints: "edit platform points",

  readAdminUsers: "read admin users",
  addAdminUsers: "add admin users",
  editAdminUsers: "edit admin users",
  readLogs: "read admin users logs",
  resetPasswordAdminUsers: "reset password admin users",
  deleteAdminUsers: "delete admin users",

  readRoles: "read roles",
  addRoles: "add roles",
  editRoles: "edit roles",
  deleteRoles: "delete roles",

  readGoogleAds: "read google ads",
  editGoogleAds: "edit google ads",
};

export { permissions };
