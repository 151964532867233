<script setup>
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { WameedFeedbackModal } from "wameed-ui";
import { useUserStore } from "../../stores/user";

const router = useRouter();
const userStore = useUserStore();
const emit = defineEmits('logout')
onMounted(() => {
  if (!userStore.profile.name) {
    userStore._getProfile();
  }
});

const changeLanguage = (e) => {
  router.push({
    name: router.currentRoute.name,
    params: {
      lang: e,
    },
  });
  setTimeout(() => {
  window.location.reload()
 }, 200);

};

</script>

<template>
  <b-nav-item-dropdown
    v-if="userStore.profile"
    right
    toggle-class="d-flex align-items-center dropdown-user-link px-0"
    class="dropdown-head-menu dropdown-user"
  >
    <template #button-content>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex justify-content-center align-center">
          <div class="user-pic">
            <img :src="userStore.profile.image" alt="" loading="lazy" />
          </div>
          <div class="d-flex flex-column justify-content-center">
            <span class="text-med-16 text-gray-1">{{
              userStore.profile.name
            }}</span>
            <span class="text-semi-12 text-gray-4 mt-3">
              {{ userStore.profile.role }}
            </span>
          </div>
        </div>
        <div >
          <arrow-down-icon2 />
        </div>
      </div>
    </template>

    <b-dropdown-item
      @click="router.push({ name: 'settings' })"
      link-class="d-flex align-items-center  "
    >
      <settings-icon class="setting-icon me-3" />
      <span class="text-med-14 text-gray-3 ms-4">{{
        $t("sidebar.settings")
      }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center  "
      @click="changeLanguage($i18n.locale == 'ar' ? 'en' : 'ar')"
    >
      <language-icon class="setting-icon me-3" />
      <span class="text-med-14 text-gray-3 ms-4"
        >{{ $t("header.change_language") + " - " }}
        {{ $i18n.locale == "ar" ? "English" : "العربيه" }}
      </span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center logout-item"
      @click="emit('logout')"
    >
      <logout-icon class="me-3" />
      <span class="text-med-14 text-error ms-4">
        {{ $t("modal.logout_btn") }}</span
      >
    </b-dropdown-item>
  </b-nav-item-dropdown>
 
</template>
