{
  "settings": {
    "main_settings": "Basic Settings",
    "main_settings_subtitle": "Platform data, social media",
    "system_users": "System Users",
    "system_users_subtitle": "users, permissions, user log",
    "profile": "Profile",
    "profile_subtitle": "Modify your profile",
    "password": "Password",
    "advance_setting": "Advanced Settings",
    "language": "Language",
    "personal_information": "Personal Data",
    "notifications_setting": "Notification Sound",
    "welcome_user": "Welcome",
    "update_personal_information": "Modify personal data",
    "change_password": "modify password",
    "reset_password": "Reset password",
    "google_ads_settings":"Google Ads Settings",
    "google_ads_subtitle":"Control the appearance of ads"
  },
  "platform":{
    "points": "points calculation mechanism",
    "points_subtitle": "Points calculation mechanism data",
    "app_explanation":"application explanation",
    "app_explanation_subtitle":"Explanation of application interfaces",
    "subscription":"Premium subscription",
    "subscription_subtitle":"Premium Settings",
    "social_media":"Follow us on social media platforms",
    "social_media_subtitle":"Social media numbers and links",
    "prizes":"Prizes",
    "prizes_subtitle":"Prizes data",
    "terms_and_conditions":"Terms and Conditions",
    "terms_and_conditions_subtitle":"Content of terms and conditions",
    "our_league":"About our league",
    "our_league_subtitle":"Information about our league",
    "about_platform":"about the platform",
    "about_platform_subtitle":"Editing your personal data",
    "faq":"Frequently Asked Questions",
    "faq_subtitle":"Frequently Asked Questions Control",
    "playing_rules":"Playing rules",
    "playing_rules_subtitle":"Playing rules Information",
    "privacy_policy":"Privacy Policy",
    "privacy_policy_subtitle":"Privacy Policy Content",
    "hide":"Hide Page",
    "show":"Show Page"
  },
  "points": {
    "title": "Points Calculation Mechanism",
    "tie_cases":"Tie Cases",
    "table": {
      "name": "Prediction status",
      "normal_match_points": "Normal match",
      "fire_match_points": "Fire match",
      "actions": "Actions"
    }
  },
  "faq": {
    "title": "FAQ",
    "create_new": "Add a new question",
    "details": "Question Details",
    "table": {
      "question":"Question",
      "answer":"answer",
      "created_at": "Created Date",
      "Status": "Status",
      "actions": "Actions"
    }
  },
  "advertisements": {
    "create_new": "Create New Ad",
    "table": {
      "profile": "Advertisement",
      "created_at": "Created date",
      "status": "Status",
      "actions": "Actions"
    },
    "table_actions": {
      "details": "Advertisement details",
      "delete": "Delete Ad"
    }
  },
  "notifications": {
    "send_new_notification": "Add a new notification",
    "table": {
      "title": "Notification name",
      "profile": "Notification title",
      "content": "Notification content",
      "target": "Target group",
      "company_name": "Directing",
      "created_at": "Created Date",
      "actions": "Actions"
    },
    "table_actions": {
      "resend": "resend"
    },
    "users":"Users",
    "targets": "target group",
    "add_target": "Add target group",
    "user": "Users",
    "all_users": "All Users",
    "specific_user": "Specific users",
    "winners": "Winners",
    "by_age": "by Age",
    "gender": "Gender",
    "all": "All",
    "specific": "Custom",
    "male_only": "Male only",
    "female_only": "Women only",
    "specific_age": "Age",
    "from_to": "from--to--",
    "select_all":"Select All",
    "date_error": "The start field must be greater than the end field."
  },
  "system-roles": {
    "create_new": "Add a new Role",
    "update_role": "Modify Role",
    "delete": "Delete",
    "table": {
      "name": "Role Name",
      "created_at": "Created date",
      "status": "Status",
      "actions": "Actions"
    },
    "table_actions": {
      "view": "View details"
    }
  },
  "permissions": {
    "sections": {
      "dashboard": "Dashboard",
      "matches": "Matches",
      "championships": "Championships",
      "teams": "Teams",
      "users": "Users",
      "winners":"Winners",
      "subscribers": "Premium Subscribers",
      "groups":"Private Leagues",
      "ads": "Ads",
      "notifications": "Notifications",
      "platform": "Platform settings",
      "admin_users": "System Users",
      "roles": "Roles",
      "user_profile": "Profile Settings",
      "google_ads": "Google Ads"
    },
    "read_dashboard": "Read dashboard",


    "read_matches": "Read Matches",
    "add_matches": "Create Matches",
    "edit_matches": "Edit Matches",

    "read_championships": "Read Championships",
    "add_championships": "Create Championships",
    "edit_championships": "Edit Championships",

    "read_teams": "Read Teams",
    "add_teams": "Create Teams",
    "edit_teams": "Edit Teams",

    "read_users": "Read users",
    "edit_users": "Edit users",
    "delete_users": "Delete users",


    "read_winners": "Read Winners",
    "add_winners": "Create Winners",

    "read_subscribers": "Read Premium Subscribers",
    "edit_subscribers": "Edit Premium Subscribers",

    "read_groups": "Read Private Leagues",
    "edit_groups": "Edit Private Leagues",

   
    "read_ads": "Read ads",
    "add_ads": "Create ads",
    "edit_ads": "Edit ads",
    "delete_ads": "Delete Ads",

    "read_notifications": "Read notifications",
    "send_notifications": "Send Notifications",
    "delete_notifications": "Delete notifications",

    "read_platform": "Read platform information",
    "edit_platform": "Edit platform information",

    "read_platform_pages": "Read Platform Pages",
    "edit_platform_pages": "Edit Platform Pages",
    
    "read_subscriptions": "Read Premium Packages",
    "edit_subscriptions": "Edit Premium Packages",

    "read_platform_faq": "Read FAQ",
    "add_platform_faq": "Create FAQ",
    "edit_platform_faq": "Edit FAQ",
    "delete_platform_faq": "Delete FAQ",
    "read_platform_points": "Read Points Calculation",
    "edit_platform_points": "Edit Points Calculation",

  
    "read_admin_users": "Read system users",
    "add_admin_users": "Create system users",
    "edit_admin_users": "Edit system users",
    "delete_admin_users": "Delete system users",
    "read_admin_users_logs": "Read system user log",
    "reset_password_admin_users": "Reset system users password",
    "read_roles": "Read roles",
    "add_roles": "Create roles",
    "edit_roles": "Edit roles",
    "delete_roles": "Delete roles",
    "read_google_ads": "Read Google Ads",
    "edit_google_ads": "Edit Google Ads"
  },
  "system-users": {
    "create_new": "Add a new user",
    "view_logs": "View Logs",
    "roles_management": "Role Management",
    "logs": "Logs",
    "resend_password": "Resend Password",
    "delete": "Delete the user",
    "table": {
      "Profile": "User",
      "email": "Email",
      "role": "Role",
      "Last Activity": "Last Activity",
      "created_at": "Created Date",
      "status": "Status",
      "actions": "Actions"
    },
    "table_actions": {
      "View": "View Details"
    }
  },
  "logs": {
    "table": {
      "user": "User name",
      "title": "Process Title",
      "content": "Process Details",
      "created_date_time": "Time and Date"
    }
  },
  "dashboard": {
    "statistics": {
      "title": "Statistics",
      "total_users":"Total Users",
       "total_predictions":"Total Predictions",
       "total_subscribers":"Premium Subscribers",
       "total_championships":"Championships",
       "total_matches":"Matches",
       "total_teams":"Teams"
     
    },
    "last_7_days": "last 7 days",
    "last_30_days": "last 30 days",
    "last_12_months": "last 12 months",
    "males": "Males",
    "females": "Females",
    "point": "point",
    "request": "request",
    "resent_users": "most recent users",
    "user": "User",
    "ended_matches":"Ended matches awaiting results",
     "total_users_by_country":"Users Statistics",
     "users_gender":"Users Gender",
     "user_joining_avarage":"Joining User Rate"
  },
  "teams": {
    "create_new": "Add New Team",
    "update":"Update Data",
    "details":"Team Details",
    "table": {
      "profile": "team",
      "championships": "Championships",
      "created_at": "Created Date",
      "actions": "Actions"
    }
  },
  "championships": {
    "create_new": "Add New Championship",
    "update":"Update Data",
    "details":"Championship Details",
    "total_matches":"Total Matches",
    "table": {
      "profile": "Championship",
      "total_teams":"Total Teams",
      "start_date":"Start Date",
      "end_date":"End Date",
      "created_at": "Created Date",
      "actions": "Actions"
    }
  },
  "matches": {
    "create_new": "Add New Match",
    "update":"Modify data",
    "details":"Match Details",
    "fire":"Fire",
    "normal":"Normal",
    "table": {
      "team_a": "First Team",
      "team_b":"Second team",
      "score":"Score",
      "match_date":"Mastch Date",
      "match_type":"Mastch Type",
      "match_result":"Status",
      "actions": "Actions"
    },
    "disabled":"Disabled",
    "waiting_for_results":"Wating For Results",
    "finished":"Completed",
    "on_going":"On Going",
    "total_predictions":"Total Predictions",
    "championship":"Championship",
    "date_time":"Time and date"
  },
  "championship_teams": {
    "table": {
      "profile": "Team",
      "total_matches":"Total Matches",
      "created_at": "Created Date",
      "actions": "Actions"
    }
  },
  "predictions":{
    "table": {
      "profile": "User Name",
      "score":"Prediction",
      "created_at": "Date",
      "prediction_result":"Prediction result",
      "actions": "Actions"
    },
    "prediction_results":{
      "0":"-",
      "1": "Less than 10%",
      "2": "Correct prediction",
      "3": "Predict correct win.",
      "4": "Predict number of goals",
      "5": "Predict correct difference",
      "6": "Wrong prediction",
      "7":"Predict number of goals and correct win."
    },
    "statistics":{
      "correct": "Correct predictions",
      "wrong":"Wrong predictions",
      "winner":"Win and draw predictions",
      "difference":"Difference prediction",
      "close":"Number of goals Predictions",
      "less_10_percentage":"Hat-trick Predictions"
    }
  },
  "winners-periods":{
    "create_new":"Add Winners",
    "details":"Winning Period Details",
    "table": {
      "period_type": "Winners Type",
      "name":"Period name",
      "period": "period",
      "total_winners":"Total Winners",
      "actions": "Actions"
    },
    "period_type":{
      "1":"Weekly",
      "2":"monthly",
      "3":"Seasonal"
    },
    "period_types":{
      "1":"weekly",
      "2":"monthly",
      "3": "Seasonal"
    },
    "choose_winners":"Choose Winners",
    "weekly_points":"Weekly Points:",
    "monthly_points":"monthly points: ",
    "yearly_points":"Yearly Points:",
    "start_date":"start date",
    "end_date":"End date",
    "total_points":"Points"
  },
  "users":{
    "update":"Update Data",
    "details":"User details",
    "table": {
      "profile": "User",
      "total_points":"Points",
      "total_predictions": "Total Predictions",
      "phone":"mobile Number",
      "created_at":"Join date",
      "status":"Status",
      "actions": "Actions"
    },
    "total_groups":"Total Private Leagues",
    "total_predictions":"Total Predictions",
    "email":"email",
    "gender":"Gender",
    "f":"Female",
    "m":"Male",
    "country":"Country",
    "birth_date":"Birth date",
    "points":"Points",
    "predictions":"Predictions",
    "yearly_rank":"Yearly Rank",
    "monthly_rank":"Monthly Rank",
    "weekly_rank":"Weekly Rank",
    "weekly_points":"Weekly Points",
    "monthly_points":"Monthly Points",
    "yearly_points":"Yearly Points"
   

  },
  "user-predictions":{
    "table": {
      "match": "Match",
      "score":"Prediction",
      "created_at": "Date",
      "prediction_result":"Prediction Result",
      "actions": "Actions"
    },
    "prediction_results":{
      "0":"-",
      "1": "Less than 10%",
      "2": "Correct prediction",
      "3": "Predict  correct win.",
      "4": "Predict Number of goals",
      "5": "Predict correct difference",
      "6": "Wrong prediction",
      "7":"Predict number of goals and correct win."
    },
    "statistics":{
      "correct": "Correct predictions",
      "wrong":"Wrong predictions",
      "winner":"Win and draw predictions",
      "difference":"Difference prediction",
      "close":"Number of goals Predictions",
      "less_10_percentage":"Hat-trick Predictions"
    }
  },
  "winners":{
    "details":"winner details",
    "table": {
      "profile": "Winner",
      "order_num":"Order",
      "points": "Points",
      "actions": "Actions"
    }
  },
  "groups":{
    "details":"league details",
    "table": {
      "profile": "league",
      "total_members":"Total Members",
      "username": "League Owner",
      "championships": "Championships",
      "created_at": "Created Date",
      "status": "Status",
      "actions": "Actions"
    },
    "total_championships":"Total Championships"
    
  },
  "members":{
    "title":"Members",
    "details":"User details",
    "table": {
      "profile": "user",
      "total_points":"Points",
      "total_predictions": "Total Predictions",
      "created_at":"Join date",
      "actions": "Actions"
    }
  },
  "group-championships": {
    "table": {
      "profile": "Championship",
      "total_teams":"Total Teams",
      "created_at": "Created Date",
      "actions": "Actions"
    }
  },
  "google_ads":{
    "type":{
      "1":"Number of Expectations Ads",
      "2":"Between Elements Ads",
      "3": "Page Footer Ads",
      "4":"Page Navigation Ads",
      "5":"Banner Ads"
    }
  },
  "subscription-products":{
    "table": {
      "product_id": "Subscription ID",
      "product_type":"Subscription Type",
      "product_period":"Duration",
      "status": "Status",
      "actions": "Actions"
    },
    "product_type":{
      "1":"Monthly",
      "2":"Annual"
    },
    "product_period":{
      "1": "month",
      "2": "years"
    },
    "total_championships":"Number of Championships"
    
  },
  "subscribers":{
    "details":"subscriber details",
    "table": {
      "profile":"user",
      "product_type":"Subscription type",
      "provider":"Store",
      "receipt_status":"Status in Store",
      "start_date": "Subscription Date",
      "expire_date": "Subscription Expiry Date",
      "status": "Subscription Status",
      "actions": "Actions"
    },
    "receipt_status":{
      "1":"Subscribed",
      "2":"Finished",
      "3":"Cancelled"
    },
    "provider":{
      "google":"Google",
      "apple":"apple"
    },
    "product_type":{
      "1":"monthly",
      "2":"annual"
    },
    "product_period":{
      "1": "month",
      "2": "years"
    },
    "subscription_details":"Subscription Information"
    
  }
}