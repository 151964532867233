<script setup>
import UserDropdown from "@/components/nav-items/UserDropdown.vue";
import NotificationDropdown from "@/components/nav-items/NotificationDropdown.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import { HeaderSearchBar } from "wameed-ui";
const { t } = useI18n();
const router = useRouter();
const emit = defineEmits('logout')
const searchData = {
  pages: {
    key: 'title',
    data: [
      { title: t('sidebar.home'), route: { name: 'home' }, icon: 'home-icon' },
      { title: t('sidebar.matches'), route: { name: 'matches' }, icon: 'status-up-icon' },
      { title: t('sidebar.championships'), route: { name: 'championships' }, icon: 'status-up-icon' },
      { title: t('sidebar.teams'), route: { name: 'teams' }, icon: 'status-up-icon' },
      { title: t('sidebar.users'), route: { name: 'users' }, icon: 'status-up-icon' },
      { title: t('sidebar.winners'), route: { name: 'winners' }, icon: 'status-up-icon' },
      { title: t('sidebar.groups'), route: { name: 'groups' }, icon: 'status-up-icon' },
      { title: t('sidebar.advertisements'), route: { name: 'advertisements' }, icon: 'status-up-icon' },
      { title: t('sidebar.notifications'), route: { name: 'notifications' }, icon: 'status-up-icon' },
      //  app_content
      { title: t('sidebar.app_content'), route: { name: 'platform' }, icon: 'status-up-icon' },
      { title: t('platform.points'), route: { name: 'points' }, icon: 'status-up-icon' },
      { title: t('platform.app_explanation'), route: { name: 'app-explanation' }, icon: 'status-up-icon' },
      { title: t('platform.subscription'), route: { name: 'subscription' }, icon: 'status-up-icon' },
      { title: t('platform.social_media'), route: { name: 'social-media' }, icon: 'status-up-icon' },
      { title: t('platform.prizes'), route: { name: 'prizes' }, icon: 'status-up-icon' },
      { title: t('platform.terms_and_conditions'), route: { name: 'terms-and-conditions' }, icon: 'status-up-icon' },
      { title: t('platform.our_league'), route: { name: 'our-league' }, icon: 'status-up-icon' },
      { title: t('platform.about_platform'), route: { name: 'platform-content' }, icon: 'status-up-icon' },
      { title: t('platform.faq'), route: { name: 'faq' }, icon: 'status-up-icon' },
      { title: t('platform.playing_rules'), route: { name: 'playing-rules' }, icon: 'status-up-icon' },
      { title: t('platform.privacy_policy'), route: { name: 'privacy-policy' }, icon: 'status-up-icon' },
      //  settings
      { title: t('sidebar.system-roles'), route: { name: 'system-roles' }, icon: 'ticket-discount-icon' },
      { title: t('system-users.logs'), route: { name: 'system-users-logs' }, icon: 'ticket-discount-icon' },
      { title: t('sidebar.system-users'), route: { name: 'system-users' }, icon: 'star-icon' },
      { title: t('settings.google_ads_settings'), route: { name: 'google-ads' }, icon: 'setting-icon' },
      { title: t('sidebar.user_profile'), route: { name: 'profile' }, icon: 'setting-icon' },
    ]
  }
}

</script>
<template>
  <b-navbar :toggleable="false" :variant="'white'"
    class="header-navbar navbar navbar-shadow align-items-center fixed-top -100">
    <div class="navbar-container d-flex content justify-content-between align-items-center h-100">
      <!-- Nav Menu Toggler -->
      <div class="d-flex align-items-center justify-content-between navbar-start-items flex-grow-1">
        <ul class="nav navbar-nav d-xl-none">
          <li class="nav-item">
            <b-link class="nav-link">
              <menu-icon class="menu-icon" @click="$emit('toggleVerticalMenuActiveEvent')" />
            </b-link>
          </li>
        </ul>

        <header-search-bar :translate="(val) => $t(val)" :searchAndBookmarkData="searchData" :router="router" />

      </div>
      <b-navbar-nav class="nav align-items-center justify-content-center">
        <notification-dropdown />
        <user-dropdown @logout="emit('logout')"/>
      </b-navbar-nav>
    </div>
  </b-navbar>
</template>
