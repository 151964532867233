{
  "header": {
    "notifications": "Notifications",
    "show_all_notifications": "View All Notifications",
    "change_language": "Change Language"
  },
  "sidebar": {
    "home": "Home",
    "matches": "Matches",
    "championships": "Championships",
    "teams": "Teams",
    "companies": "Companies",
    "users": "Users",
    "advertisements": "Advertisements",
    "notifications": "Notifications",
    "app_content": "App Content",
    "settings": "Settings",
    "platform": "Platform",
    "system-roles": "System Roles",
    "system-users": "System Users",
    "user_profile": "Profile",
    "winners":"Winners",
    "subscribers":"Premium Subscribers",
    "groups":"Private Leagues"

  },
  "footer": {
    "all_rights_reserved": "All Right Reserved for",
    "platform":"Go All Fanz",
    "made_by": "Made in",
    "wameed": "Wameed"
  }
}