import main from "./main.json";
import common from "./common.json";
import authentication from "./authentication.json";
import apiResponse from "./api-response.json";
import layout from "./main-layout.json";
const ar = {
  ...main,
  ...common,
  ...authentication,
  ...apiResponse,
  ...layout,
};
export default ar;
