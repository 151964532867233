<script setup>
import { WameedButton } from "wameed-ui";
import { onMounted, ref } from "vue";

import { useRouter } from "vue-router";
const router = useRouter();
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();
const lastNotifications = ref([]);
onMounted(() => {
  userStore.loadNotifications().then((res) => {
    if (res == true)
      lastNotifications.value = userStore.getNotifications.slice(0, 4);
  })
});
</script>
<template>
  <b-nav-item-dropdown class="dropdown-notification" menu-class="dropdown-menu-media mx-4" right>
    <template #button-content>
      <li class="nav-item notification-nav-item ">
        <span class="custom-notification-btn position-relative ">
          <notification-icon />

          <span v-if="userStore.profile && userStore.profile.unread_notifications > 0
            " class="custom-notification-badge">
            {{ userStore.profile.unread_notifications }}
          </span>
        </span>
      </li>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex justify-content-between algin-items-center">
        <h4 class="notification-title mb-0 mr-auto text-bold-18 text-gray-1">
          {{ $t("header.notifications") }}
        </h4>
        <div class="my-auto">
          <b-badge v-if="userStore.profile && userStore.profile.unread_notifications > 0
            " pill variant="light-primary" class="notification-badge py-3 px-4 text-med-12">
            {{ userStore.profile.unread_notifications }} {{ $t("common.new") }}
          </b-badge>
        </div>
      </div>
    </li>

    <!-- Notifications -->
    <perfect-scrollbar v-if="lastNotifications" v-once class="scrollable-container media-list scroll-area" tagname="li">
      <!-- Account Notification -->

      <!-- System Notifications -->
      <b-link v-for="(notification, index) in lastNotifications" :key="index" :to="{
        name: 'user-notifications',
        params: {
          lang: $i18n.locale,
        },
      }">
        <div class="media d-flex">
          <b-avatar size="40" class="mx-4" :class="{
            'new-notification': notification.is_read == 0,
          }" :variant="notification.is_read == 0 ? 'primary-100' : 'gray-100'">
            <notification-icon
              :class="{ 'svg-stroke-primary svg-primary-100': notification.is_read == 0, 'svg-stroke-gray-6 svg-gray-100': notification.is_read == 1, }" />

          </b-avatar>
          <div>
            <div class="media-heading d-flex justify-content-between">
              <span class="text-bold-14 text-gray-2">
                {{ notification.name }}
              </span>
              <!-- <span class="text-med-12 text-gray-4">
                {{ formatDate(notification.created_at) }}
              </span> -->
            </div>
            <small class="text-med-14 text-gray-3 max-1-lines">{{
              notification.content
            }}</small>
          </div>
        </div>
      </b-link>
    </perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="d-grid dropdown-menu-footer">
      <wameed-button :title="$t('header.show_all_notifications')" @click="
        router.push({
          name: 'user-notifications',
          params: {
            lang: $i18n.locale,
          },
        })
        " variant="outline-primary-600" :classes="' text-med-16 text-primary-600 rounded-12'" />
    </li>
  </b-nav-item-dropdown>
</template>
