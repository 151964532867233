import { h, resolveComponent } from "vue";
import { permissions } from "@/utils/permissions-enum";
import middleware from "../middleware/middleware";
export default [
  {
    path: "login",
    name: "login",
    component: () => import("@/views/authentication/Login.vue"),
    meta: {
      middleware: middleware,
      layout: "auth",
      resource: "Auth",
    },
  },
  {
    path: "forgot-password",
    name: "forgot-password",
    component: () => import("@/views/authentication/ForgotPassword.vue"),
    meta: {
      middleware: middleware,
      layout: "auth",
      resource: "Auth",
    },
  },
  {
    path: "reset-password/:token",
    name: "reset-password",
    component: () => import("@/views/authentication/ResetPassword.vue"),
    meta: {
      middleware: middleware,
      layout: "auth",
      resource: "Auth",
    },
  },
  {
    path: "validate-email",
    name: "validate-email",
    component: () => import("@/views/authentication/ValidateEmail.vue"),
    meta: {
      middleware: middleware,
      layout: "auth",
      resource: "Auth",
    },
  },
  {
    path: "",
    name: "home",
    component: () => import("@/views/dashboard/index.vue"),
    meta: {
      middleware: middleware,
      title: "sidebar.home",
      breadcrumbs: [],
    },
  },
  {
    path: "user-notifications",
    name: "user-notifications",
    component: () => import("@/views/user/Notifications.vue"),
    meta: {
      middleware: middleware,
      title: "sidebar.home",
      breadcrumbs: [
        {
          disabled: true,
          path: "notifications",
          title: "sidebar.notifications",
        },
      ],
    },
  },
  {
    path: "matches",

    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "",
        name: "matches",
        component: () => import("@/views/matches/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.matches",
          breadcrumbs: [
            {
              path: "matches",
              title: "sidebar.matches",
              disabled: true,
            }
          ],
          permissions: [permissions.readMatches],
          pagePermissions: {
            create: permissions.addMatches,
            edit: permissions.editMatches,
            read: permissions.readMatches,
          },
        },
      },
      {
        path: ":id",
        name: "matches-details",
        component: () => import("@/views/matches/details.vue"),
        meta: {
          middleware: middleware,
          title: "matches.details",
          breadcrumbs: [
            {
              path: "matches",
              title: "sidebar.matches",
            },
            {
              path: "matches-details",
              title: "matches.details",
              disabled: true,
            }
          ],
          permissions: [permissions.readMatches],
          pagePermissions: {
            edit: permissions.editMatches,
            read: permissions.readMatches,
          },
        },
      },


    ],
  },
  {
    path: "championships",

    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "",
        name: "championships",
        component: () => import("@/views/championships/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.championships",
          breadcrumbs: [
            {
              path: "championships",
              title: "sidebar.championships",
              disabled: true,
            }
          ],
          permissions: [permissions.readChampionships],
          pagePermissions: {
            create: permissions.addChampionships,
            edit: permissions.editChampionships,
            read: permissions.readChampionships,
          },
        },
      },
      {
        path: ":id",
        name: "championships-details",
        component: () => import("@/views/championships/details.vue"),
        meta: {
          middleware: middleware,
          title: "championships.details",
          breadcrumbs: [
            {
              path: "championships",
              title: "sidebar.championships",
            },
            {
              path: "championships-details",
              title: "championships.details",
              disabled: true,
            }
          ],
          permissions: [permissions.readChampionships],
          pagePermissions: {
            edit: permissions.editChampionships,
            read: permissions.readChampionships,
          },
        },
      },
    ],
  },
  {
    path: "teams",

    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "",
        name: "teams",
        component: () => import("@/views/teams/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.teams",
          breadcrumbs: [
            {
              path: "teams",
              title: "sidebar.teams",
              disabled: true,
            }
          ],
          permissions: [permissions.readTeams],
          pagePermissions: {
            create: permissions.addTeams,
            edit: permissions.editTeams,
            read: permissions.readTeams,
          },
        },
      },
      {
        path: ":id",
        name: "teams-details",
        component: () => import("@/views/teams/details.vue"),
        meta: {
          middleware: middleware,
          title: "teams.details",
          breadcrumbs: [
            {
              path: "teams",
              title: "sidebar.teams",
            },
            {
              path: "teams-details",
              title: "teams.details",
              disabled: true,
            }
          ],
          permissions: [permissions.readTeams],
          pagePermissions: {
            edit: permissions.editTeams,
            read: permissions.readTeams,
          },
        },
      },


    ],
  },
  {
    path: "users",

    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "",
        name: "users",
        component: () => import("@/views/users/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.users",
          breadcrumbs: [
            {
              path: "users",
              title: "sidebar.users",
              disabled: true,
            }
          ],
          permissions: [permissions.readUsersInfo],
          pagePermissions: {
            edit: permissions.editUsers,
            read: permissions.readUsersInfo,
          },
        },
      },
      {
        path: ":id",
        name: "users-details",
        component: () => import("@/views/users/details.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.users",
          breadcrumbs: [
            {
              path: "users",
              title: "sidebar.users",
            },
            {
              path: "users-details",
              title: "users.details",
              disabled: true,
            }
          ],
          permissions: [permissions.readUsersInfo],
          pagePermissions: {
            edit: permissions.editUsers,
            read: permissions.readUsersInfo,
          },
        },
      },
      {
        path: ":id/update",
        name: "users-update",
        component: () => import("@/views/users/update.vue"),
        meta: {
          middleware: middleware,
          title: "users.update",
          breadcrumbs: [
            {
              path: "users",
              title: "sidebar.users",
            },
            {
              path: "users-update",
              title: "users.update",
              disabled: true,
            }
          ],
          permissions: [permissions.editUsers],
          pagePermissions: {
            edit: permissions.editTeams,
            read: permissions.readUsersInfo,
          },
        },
      },


    ],
  },
  {
    path: "winners",

    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "",
        name: "winners",
        component: () => import("@/views/winners/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.winners",
          breadcrumbs: [
            {
              path: "winners",
              title: "sidebar.winners",
              disabled: true,
            }
          ],
          permissions: [permissions.readWinner],
          pagePermissions: {
            create: permissions.addWinner,
            read: permissions.readWinner,
          },
        },
      },
      {
        path: ":id",
        name: "winners-periods-details",
        component: () => import("@/views/winners/details.vue"),
        meta: {
          middleware: middleware,
          title: "winners-periods.details",
          breadcrumbs: [
            {
              path: "winners",
              title: "sidebar.winners",
            },
            {
              path: "winners-periods-details",
              title: "winners-periods.details",
              disabled: true,
            }
          ],
          permissions: [permissions.readWinner],
          pagePermissions: {
            read: permissions.readWinner,
          },
        },
      },
      {
        path: ":WinningId/user/:id",
        name: "winners-details",
        component: () => import("@/views/users/details.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.winners",
          breadcrumbs: [
            {
              path: "winners",
              title: "sidebar.winners",
            },
            {
              path: "winners-details",
              title: "winners.details",
              disabled: true,
            }
          ],
          permissions: [permissions.readWinner],

        },
      },


    ],
  },
  {
    path: "subscribers",

    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "",
        name: "subscribers",
        component: () => import("@/views/subscribers/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.subscribers",
          breadcrumbs: [
            {
              path: "subscribers",
              title: "sidebar.subscribers",
              disabled: true,
            }
          ],
          permissions: [permissions.readSubscribers],
          pagePermissions: {
            edit: permissions.editSubscribers,
            read: permissions.readSubscribers,
          },
        },
      },
      {
        path: ":id",
        name: "subscribers-details",
        component: () => import("@/views/users/details.vue"),
        meta: {
          middleware: middleware,
          title: "subscribers.details",
          breadcrumbs: [
            {
              path: "subscribers",
              title: "sidebar.subscribers",
            },
            {
              path: "subscribers-details",
              title: "subscribers.details",
              disabled: true,
            }
          ],
          permissions: [permissions.readSubscribers],
          pagePermissions: {
            read: permissions.readSubscribers,
          },
        },
      },
     

    ],
  },
  {
    path: "groups",

    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "",
        name: "groups",
        component: () => import("@/views/groups/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.groups",
          breadcrumbs: [
            {
              path: "groups",
              title: "sidebar.groups",
              disabled: true,
            }
          ],
          permissions: [permissions.readGroups],
          pagePermissions: {
            edit: permissions.editGroups,
            read: permissions.readGroups,
          },
        },
      },
      {
        path: ":id",
        name: "groups-details",
        component: () => import("@/views/groups/details.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.groups",
          breadcrumbs: [
            {
              path: "groups",
              title: "sidebar.groups",
            },
            {
              path: "groups-details",
              title: "groups.details",
              disabled: true,
            }
          ],
          permissions: [permissions.readGroups],
          pagePermissions: {
            edit: permissions.editGroups,
            read: permissions.readGroups,
          },
        },
      },



    ],
  },
  {
    path: "advertisements",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        middleware: middleware,
        path: "",
        name: "advertisements",
        component: () => import("@/views/advertisements/index.vue"),
        meta: {
          title: "sidebar.advertisements",
          breadcrumbs: [
            {
              path: "home",
              title: "sidebar.home",
            },
            {
              disabled: true,
              path: "advertisements",
              title: "sidebar.advertisements",
            },
          ],
          permissions: [permissions.readAds],
          pagePermissions: {
            create: permissions.addAds,
            edit: permissions.editAds,
            read: permissions.readAds,
          },
        },
      },
    ],
  },
  {
    path: "notifications",
    name: "notifications",
    component: () => import("@/views/notifications/index.vue"),
    meta: {
      title: "sidebar.notifications",
      middleware: middleware,
      breadcrumbs: [
        {
          disabled: true,
          path: "notifications",
          title: "sidebar.notifications",
        },
      ],
      permissions: [permissions.readNotifications],
      pagePermissions: {
        read: permissions.readNotifications,
        send: permissions.sendNotifications,
        delete: permissions.deleteNotifications,
      },
    },
  },
  {
    path: "platform",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "",
        name: "platform",
        component: () => import("@/views/platform/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.settings",
          breadcrumbs: [
            {
              disabled: true,
              path: "settings",
              title: "sidebar.settings",
            },
          ],

        },
      },
      {
        path: "points",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        children: [
          {
            path: "",
            name: "points",

            component: () => import("@/views/platform/points/index.vue"),
            meta: {
              middleware: middleware,
              title: "platform.points",
              breadcrumbs: [
                {
                  path: "platform",
                  title: "sidebar.app_content",
                },
                {
                  disabled: true,
                  path: "points",
                  title: "platform.points",
                },
              ],
              permissions: [permissions.readPlatformPoints],
              pagePermissions: {
                read: permissions.readPlatformPages,
                edit: permissions.editPlatformPoints,
              },
            },
          },
          {
            path: "content",
            name: "points-content",

            component: () => import("@/views/platform/Page.vue"),
            meta: {
              middleware: middleware,
              title: "points.tie_cases",
              breadcrumbs: [
                {
                  path: "platform",
                  title: "sidebar.app_content",
                },
                {
                  path: "points",
                  title: "platform.points",
                },
                {
                  disabled: true,
                  path: "points",
                  title: "points.tie_cases",
                },
              ],
              permissions: [permissions.readPlatformPages],
              pagePermissions: {
                edit: permissions.editPlatformPages,
              },
              pageKey: 'points',
              editTitle: false
            },
          },
        ]
      },
      {
        path: "app-explanation",
        name: "app-explanation",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.app_explanation",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content",
            },
            {
              disabled: true,
              path: "app-explanation",
              title: "platform.app_explanation",
            },
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages,
          },
          pageKey: 'app-explanation',
          editTitle: false
        },
      },
      {
        path: "subscription",
        name: "subscription",
        component: () => import("@/views/platform/Subscription.vue"),
        meta: {
          middleware: middleware,
          title: "platform.subscription",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content",
            },
            {
              disabled: true,
              path: "subscription",
              title: "platform.subscription",
            },
          ],
          permissions: [permissions.readSubscriptions],
          pagePermissions: {
            edit: permissions.editSubscriptions,
          },
        },
      },
      {
        path: "social-media",
        name: "social-media",
        component: () => import("@/views/platform/SocialMedia.vue"),
        meta: {
          middleware: middleware,
          title: "platform.social_media",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content",
            },
            {
              disabled: true,
              path: "social-media",
              title: "platform.social_media",
            },
          ],
          permissions: [permissions.readPlatform],
          pagePermissions: {
            edit: permissions.editPlatform,
          },
        },
      },
      {
        path: "prizes",
        name: "prizes",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.prizes",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content",
            },
            {
              disabled: true,
              path: "prizes",
              title: "platform.prizes",
            },
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages,
          },
          pageKey: 'prizes',
          editTitle: true
        },
      },
      {
        path: "terms-and-conditions",
        name: "terms-and-conditions",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.terms_and_conditions",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content",
            },
            {
              disabled: true,
              path: "terms-and-conditions",
              title: "platform.terms_and_conditions",
            },
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages,
          },
          pageKey: 'terms-and-conditions',
          editTitle: false
        },
      },
      {
        path: "our-league",
        name: "our-league",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.our_league",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content",
            },
            {
              disabled: true,
              path: "our-league",
              title: "platform.our_league",
            },
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages,
          },
          pageKey: 'our-league',
          editTitle: true
        },
      },

      {
        path: "content",
        name: "platform-content",
        component: () => import("@/views/platform/Content.vue"),
        meta: {
          middleware: middleware,
          title: "platform.about_platform",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content",
            },
            {
              disabled: true,
              path: "platform-content",
              title: "platform.about_platform",
            },
          ],
          permissions: [permissions.readPlatform],
          pagePermissions: {
            edit: permissions.editPlatform,
          },
        },
      },
      {
        path: "faq",
        name: "faq",
        component: () => import("@/views/platform/Faq.vue"),
        meta: {
          middleware: middleware,
          title: "platform.faq",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content",
            },
            {
              disabled: true,
              path: "faq",
              title: "platform.faq",
            },
          ],
          permissions: [permissions.readPlatformFaq],
          pagePermissions: {
            create: permissions.createPlatformFaq,
            edit: permissions.editPlatformFaq,
            delete: permissions.deletePlatformFaq,
          },
        },
      },
      {
        path: "playing-rules",
        name: "playing-rules",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.playing_rules",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content",
            },
            {
              disabled: true,
              path: "playing-rules",
              title: "platform.playing_rules",
            },
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages,
          },
          pageKey: 'playing-rules',
          editTitle: true
        },
      },
      {
        path: "privacy-policy",
        name: "privacy-policy",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.privacy_policy",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content",
            },
            {
              disabled: true,
              path: "privacy-policy",
              title: "platform.privacy_policy",
            },
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages,
          },
          pageKey: 'privacy-policy',
          editTitle: false
        },
      },
    ],
  },
  {
    path: "settings",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "",
        name: "settings",
        component: () => import("@/views/settings/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.settings",
          breadcrumbs: [
            {
              disabled: true,
              path: "settings",
              title: "sidebar.settings",
            },
          ],
        },
      },

      {
        path: "system-users",

        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        children: [
          {
            path: "",
            name: "system-users",
            component: () => import("@/views/system-users/index.vue"),
            meta: {
              middleware: middleware,
              title: "sidebar.system-users",
              breadcrumbs: [
                {
                  path: "settings",
                  title: "sidebar.settings",
                },
                {
                  disabled: true,
                  path: "system-users",
                  title: "sidebar.system-users",
                },
              ],
              permissions: [permissions.readAdminUsers],
              pagePermissions: {
                logs: permissions.readLogs,
                create: permissions.addAdminUsers,
                edit: permissions.editAdminUsers,
                read: permissions.readRoles,
                reset: permissions.resetPasswordAdminUsers,
                delete: permissions.deleteAdminUsers,
              },
            },
          },
          {
            path: "logs",
            name: "system-users-logs",
            component: () => import("@/views/system-users/Logs.vue"),
            meta: {
              middleware: middleware,
              title: "system-users.logs",
              breadcrumbs: [
                {
                  path: "settings",
                  title: "sidebar.settings",
                },
                {
                  path: "system-users",
                  title: "sidebar.system-users",
                },
                {
                  disabled: true,
                  path: "system-users-logs",
                  title: "system-users.logs",
                },
              ],
              permissions: [permissions.readLogs],
            },
          },
          {
            path: "system-roles",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            children: [
              {
                path: "",
                name: "system-roles",
                component: () => import("@/views/system-roles/index.vue"),
                meta: {
                  middleware: middleware,
                  title: "sidebar.system-roles",
                  breadcrumbs: [
                    {
                      path: "settings",
                      title: "sidebar.settings",
                    },
                    {
                      path: "system-users",
                      title: "sidebar.system-users",
                    },
                    {
                      disabled: true,
                      path: "system-roles",
                      title: "sidebar.system-roles",
                    },
                  ],
                  permissions: [permissions.readRoles],
                  pagePermissions: {
                    edit: permissions.editRoles,
                    read: permissions.readRoles,
                    delete: permissions.deleteRoles,
                    create: permissions.addRoles,
                  },
                },
              },
              {
                path: "create",
                name: "system-roles-create",
                component: () => import("@/views/system-roles/Create.vue"),
                meta: {
                  middleware: middleware,
                  title: "system-roles.create_new",
                  breadcrumbs: [
                    {
                      path: "settings",
                      title: "sidebar.settings",
                    },
                    {
                      path: "system-users",
                      title: "sidebar.system-users",
                    },
                    {
                      path: "system-roles",
                      title: "sidebar.system-roles",
                    },
                    {
                      disabled: true,
                      path: "system-roles-create",
                      title: "system-roles.create_new",
                    },
                  ],
                  permissions: [permissions.addRoles],
                },
              },
              {
                path: ":id",
                name: "system-roles-details",
                component: () => import("@/views/system-roles/Update.vue"),
                meta: {
                  middleware: middleware,
                  title: "system-roles.update_role",
                  breadcrumbs: [
                    {
                      path: "settings",
                      title: "sidebar.settings",
                    },
                    {
                      path: "system-users",
                      title: "sidebar.system-users",
                    },
                    {
                      path: "system-roles",
                      title: "sidebar.system-roles",
                    },
                    {
                      disabled: true,
                      path: "system-roles-details",
                      title: "system-roles.update_role",
                    },
                  ],
                  permissions: [permissions.editRoles],
                  pagePermissions: {
                    delete: permissions.deleteRoles,
                  }
                },
              },
            ],
          },
        ],
      },
      {
        path: "profile",
        name: "profile",
        component: () => import("@/views/user/index.vue"),
        meta: {
          middleware: middleware,
          title: "settings.profile",
          breadcrumbs: [
            {
              path: "settings",
              title: "sidebar.settings",
            },
            {
              disabled: true,
              path: "profile",
              title: "settings.profile",
            },
          ],
        },
      },
      {
        path: "google-ads",
        name: "google-ads",
        component: () => import("@/views/settings/GoogleAds.vue"),
        meta: {
          middleware: middleware,
          title: "settings.google_ads_settings",
          breadcrumbs: [
            {
              path: "settings",
              title: "sidebar.settings",
            },
            {
              disabled: true,
              path: "google-ads",
              title: "settings.google_ads_settings",
            },
          ],
          permissions: [permissions.readGoogleAds],
          pagePermissions: {
            read: permissions.readPlatform,
            edit: permissions.editGoogleAds,
          }
        },
      },
    ],
  },

];
