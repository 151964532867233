import { permissions } from "@/utils/permissions-enum";

export default [
  {
    title: "home",
    icon: "home-icon",
    route: "home",
    permissions: [],
  },
  
  {
    title: "matches",
    icon: "matches-icon",
    route: "matches",
    permissions: [permissions.readMatches],

  },
  {
    title: "teams",
    icon: "teams-icon",
    route: "teams",
    permissions: [permissions.readTeams],

  },
  {
    title: "championships",
    icon: "championships-icon",
    route: "championships",
    permissions: [permissions.readChampionships],

  },
 
  {
    title: "users",
    icon: "users-icon",
    route: "users",
    permissions: [permissions.readUsersInfo],

  },
  {
    title: "subscribers",
    icon: "subscription-icon",
    route: "subscribers",
    permissions: [permissions.readSubscribers],

  },
  {
    title: "winners",
    icon: "winners-icon",
    route: "winners",
    permissions: [permissions.readWinner],

  },
  {
    title: "groups",
    icon: "groups-icon",
    route: "groups",
    permissions: [permissions.readGroups],
  },
  {
    title: "advertisements",
    icon: "advertisements-icon",
    route: "advertisements",
    permissions: [permissions.readAds],
  },
  {
    title: "notifications",
    icon: "notifications-icon",
    route: "notifications",
    permissions: [permissions.readNotifications],
  },
  {
    title: "app_content",
    icon: "app-content-icon",
    route: "platform",
    permissions: [permissions.readPlatform, permissions.readPlatformPages, permissions.readPlatformFaq, permissions.readPlatformPoints],
  },
  {
    title: "settings",
    icon: "settings-icon",
    route: "settings",
    permissions: [],
  },
];
