import { defineStore } from "pinia";
// import events from '@/fake'
export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    notifications: null,
    profile: {
      permissions: null,
    },
  }),
  getters: {
    getNotifications: (state) => state.notifications,
    getPermissions: (state) => state.profile?.permissions,
  },
  actions: {
    async _getProfile() {
      this.$wameedLoading("loading-overly", true);
    return  await this.$http
        .get("/me")
        .then((response) => {
          if (response.status === 200) {
            this.profile = response.data.data.content;
            this.$wameedLoading("loading-overly", false);
            return
          }
        })
        .catch((err) => {
          this.$wameedLoading("loading-overly", false);
          throw err;
        });
    },
    async _updateProfile(data) {
      this.$wameedLoading("profile-update", true);
      await this.$http
        .patch("/me", data)
        .then((response) => {
          if (response.status === 200) {
            this.profile = response.data.data.content;
            this.$wameedLoading("profile-update", false);
            this.$wameedNotification({
              title: "information_saved",
              type: "success",
            });
          }
        })
        .catch((err) => {
          this.$wameedLoading("profile-update", false);
          throw err;
        });
    },
    async _resetPassword(data) {
      this.$wameedLoading("change-password", true);
      return await this.$http
        .patch("/me/change-password", data)
        .then((response) => {
          if (response.status === 200) {
            this.$wameedLoading("change-password", false);
            this.$wameedNotification({
              title: "password_changed",
              type: "success",
            });
            return true;
          }
        })
        .catch((err) => {
          this.$wameedLoading("change-password", false);
          throw err;
        });
    },

    async loadNotifications() {
      this.$wameedLoading("loading-overly", true);
      return await this.$http
        .get("/me/notifications")
        .then((response) => {
          this.notifications = response.data.data.content;
          this.$wameedLoading("loading-overly", false);
          return true
        })
        .catch((err) => {
          this.$wameedLoading("loading-overly", false);
          throw err;
        });
    },
    async readNotifications(data) {
      this.$wameedLoading("loading-overly", true);
      return await this.$http
        .post("/me/notifications/read", data)
        .then((response) => {
          this.$wameedLoading("loading-overly", false);
          if(this.profile.unread_notifications){
            this.profile.unread_notifications=0
          }
        })
        .catch((err) => {
          this.$wameedLoading("loading-overly", false);
          throw err;
        });
    },
  },
});
