<script setup>
import { WameedButton } from "wameed-ui";
import { useRouter } from "vue-router";
const router = useRouter();
</script>

<template>
  <div
    class="d-flex flex-column justify-contnet-center align-items-center fullpage-content"
  >
    <not-found   />
    <h3 class="text-bold-24 text-gray-2 my-5 pt-5">
      {{ $t("no_data.page_notfound") }}
    </h3>
    <h5 class="text-med-20 text-gray-4 mb-4   pb-5">
      {{ $t("no_data.page_notfound_message") }}
    </h5>
    <div  >
      <wameed-button
        @onClick="
          router.push({
            name: 'home',
            params: {
              lang: $i18n.locale,
            },
          })
        "
        variant="primary-600"
        :title="$t('no_data.backhome')"
        classes="  text-med-16 text-white mt-4 "
        appendIcon="arrow-icon"
        block
      />
    </div>
  </div>
</template>
