{
  "login": {
    "title": "تسجيل الدخول",
    "subtitle": "يرجى إدخال البيانات لتتمكن من تسجيل الدخول",
    "forgot_password": "هل نسيت كلمة المرور؟",
    "login": "تسجيل الدخول"
  },
  "forgot_password": {
    "title": "نسيان كلمة المرور",
    "subtitle": "يرجى إدخال البريد الإلكتروني الموجود لدينا",
    "login_page": "صفحة تسجيل الدخول",
    "or": "او"
  },
  "reset_code": {
    "title": "رمز إعادة تعيين كلمة المرور",
    "subtitle": "أدخل رمز التحقق الذي تم إرساله إلى بريدك الإلكتروني",
    "nothing_received": "لم يصلك الرمز ؟"
  },
  "reset_password": {
    "title": "إعادة تعيين كلمة المرور",
    "subtitle": "يرجى ادخال كلمة المرور الجديدة لحسابك"
  }
}