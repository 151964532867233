{
  "common": {
    "ar": "العربية",
    "en": "Engilsh",
    "new": "New",
    "order_by": "Order by",
    "newest": "Newest first",
    "older": "Oldest first",
    "alphabetical": "alphabetically a-z",
    "show_results": "show results",
    "show_details": "show details",
    "filter_data": "filter items",
    "search": "search for data",
    "back": "Back",
    "main_information": "Primary Information",
    "social_information": "Social Information",
    "upload": "Upload",
    "optional": "Optional",
    "match":"Match",
    "team":"Team",
    "goal":"Goal", 
    "point":"Point",
    "winners":"Winners",
    "predictions":"Predictions",
    "members":"Members",
    "no_results":"No results found",
    "crop_image":"Edit Image",
    "image_preview":"Preview Result"
  },
  "actions": {
    "cancel": "Cancel",
    "send": "Send",
    "save": "Save",
    "add": "Add",
    "save_updates": "Save updates",
    "show_all": "Show all",
    "resend": "Resend",
    "reset": "Reset",
    "apply": "Apply",
    "confirm": "Confirm",
    "view":"View Details",
    "details": "View Details",
    "info": "View Details",
    "update": "Update Data",
    "alter": "Update Data",
    "activate": "Activate",
    "disable": "Disable",
    "block": "Block",
    "delete":"Delete",
    "edit_points":"Update Points",
    "stop":"Stop",
    "match_result":"Add Match Result",
    "stop_prediction":"Stop Prediction",
    "disable_ads": "Disable Google Ads",
    "activate_ads": "Activate Google Ads"
  },
  "no_data": {
    "no_items": "No items",
    "no_items_message": "Sorry, you haven't added any items yet",
    "no_data": "No data found",
    "no_data_message": "No data was registered until now",
    "page_notfound": "Sorry, this page is not available",
    "page_notfound_message": " Sorry, this page does not exist, it may have been deleted, or moved to a new location.",
    "backhome": "Back home",
    "internet_connection": "There is a connection problem",
    "inernet_connection_message": "Please check your internet connection and refresh the page",
    "refresh": "Refresh",
    "no_notifications": "No notifications",
    "no_notifications_message": "Sorry, no notifications have been sent yet",
    "no_more_results": "No more results!",
    "no_permissions": "You have no permissions",
    "no_permissions_message": "Sorry, you have no permissions to view this content."
  },
  "forms": {
    "search": {
      "label": "search"
    },
    "phone_number": {
      "label": "Phone number"
    },
    "email": {
      "label": "Email"
    },
    "password": {
      "label": "Password"
    },
    "password_confirmation": {
      "label": "Confirm password"
    },
    "new_password": {
      "label": "New password"
    },
    "old_password": {
      "label": "Old password"
    },
    "confirm_new_password": {
      "label": "Confirm new password"
    },
    "notification_name": {
      "label": "Notification Name"
    },
    "notification_title": {
      "label": "Notification Title"
    },
    "notification_image": {
      "label": "Notification Image"
    },
    "redirect": {
      "label": "Redirect"
    },
    "notification_content": {
      "label": "Notification Content"
    },
    "ads_title": {
      "label": "Advertisement Title"
    },
    "ads_image": {
      "label": "Advertisement Image"
    },
    "platform_image": {
      "label": "Platform image"
    },
    "platform_name": {
      "label": "Platform Name"
    },
    "platform_description": {
      "label": "Platform Description"
    },
    "facebook": {
      "label": "Facebook"
    },
    "instagram": {
      "label": "Instagram"
    },
    "twitter": {
      "label": "Twitter"
    },
    "youtube": {
      "label": "Youtube"
    },
    "tiktok": {
      "label": "Tiktok"
    },
    "role": {
      "label": "Role",
      "placeholder": "Write Role Name"
    },
    "role_name": {
      "label": "Name",
      "placeholder": "Write Role Name"
    },
    "user_name": {
      "label": "User Name"
    },
    "profile_image": {
      "label": "Profile Image",
      "placeholder": "upload Image"
    },
    "profile_images": {
      "label": "Profile Images",
      "placeholder": "upload Images"
    },
    "full_name": {
      "label": "Full Name"
    },
    "message_title": {
      "label": "Message Title"
    },
    "message_content": {
      "label": "Message Content"
    },
    "contact_email": {
      "label": "Contact Email"
    },
    "support_email": {
      "label": "Support Email"
    },
    "verification_code": {
      "label": "Verification Code"
    },
    "other_phone_number": {
      "label": "Other Phone Number"
    },
    "content":{
      "label": "content"

    },
    "page_title":{
      "label": "Page Title"

    },
    "question":{
      "label": "Question"
    },
    "answer":{
      "label": "answer"
    },
    "team_image":{
      "label": "Team image"
    },
    "team_name":{
      "label": "Team Name"
    },
    "championship_image":{
      "label": "Championship image"
    },
    "championship_name":{
      "label": "Championship Name"
    },
    "start_date":{
      "label": "Start Date"
    },
    "end_date":{
      "label": "End Date"
    },
    "joined_teams":{
      "label": "Joined Teams"
    },
    "championship":{
      "label": "Championship"
    },
    "team_a":{
      "label":"First Team"
    },
    "team_b":{
      "label": "Second Team"
    },
    "match_type":{
      "label": "Match Type"
    },
    "match_date":{
      "label": "Date and Time"
    },
    "team_a_score":{
      "label":"First Team Score"
    },
    "team_b_score":{
      "label": "Second Team Score"
    },
    "period_type":{
      "label":  "Period Type"
    },
    "period_name":{
      "label":"Period Name"
    },
    "winners":{
      "label":"Winners"
    },
    "country":{
      "label":"Country"
    },
    "gender":{
      "label":"Gender"
    },
    "birth_date":{
      "label":"Birth date"
    },
    "android_code":{
      "label":"Android Code"
    },
    "ios_code":{
      "label":"IOS Code"
    },
    "number_of_elements":{
      "label":"Number Of Elements"
    },
    "number_of_predictions":{
      "label":"Number Of Predictions"
    },
    "winners_type":{
      "label":"Winners Type"
    },
    "period":{
      "label":"Period"
    }
  },
  "modal": {
    "logout": "You are about to logout",
    "logout_btn": "Logout",
    "logout_message": "Do you really want to log out, we will miss you so much, we hope you'll be back soon",
    "confirm_updating_profile": "Do you want to update your data",
    "confirm_updating_profile_message": "Your data will be changed for the new entries you added",
    "delete_title_advertisements": "You are about to delete this ad",
    "delete_subtitle_advertisements": "You are about to delete the ad permanently, are you sure you want to delete",
    "delete_title_system_roles": "You are about to delete this permission",
    "delete_subtitle_system_roles": "You are about to delete the permission permanently, are you sure you want to delete",
    "delete_title_system_users": "You are about to delete this user",
    "delete_subtitle_system_users": "You are about to delete the user permanently, are you sure you want to delete",
    "send_notification": "Send Notifications",
    "send_notification_subtitle": "When approved, the notification will be sent to all users, and you will not be able to undo this notification.",
    "delete_title_faq": "You are about to delete this question",
    "delete_subtitle_faq": "You are about to delete the question. This will permanently delete the question. Are you sure you want to delete?",
    "disable_title_teams": "Are you sure you want to disable?",
    "disable_subtitle_teams": "When a team is disabled, this team will not be displayed in future matches",
    "disable_title_championships": "Are you sure you want to disable?",
    "disable_subtitle_championships": "When the championship is disabled, it will not be shown in future matches",
    "disable_title_matches": "Are you sure you want to disable the match?",
    "disable_subtitle_matches": "When the match is disabled, all predictions will be canceled and hidden from the application",
    "stop_title_matches": "Are you sure you want to stop predictions?",
    "stop_subtitle_matches": "When you stop predictions, users will not be able to make predictions, and you will not be able to reactivate predictions.",
    "disable_title_users": "Are you sure you want to block the user?",
    "disable_subtitle_users": "When the user is blocked, He will not be able to log in to the application and will not appear in any results.",
    "disable_title_google_ads": "Are you sure you want to disable Google Ads?",
    "disable_subtitle_google_ads": "When you disable Google Ads, they will be hidden from the application and will not appear until you reactivate them",
    "confirm match result": "Match result {team_a} : {team_b}", 
    "confirm__match_result_message": "You will not be able to update the match result after confirmation",
    "confirm_winner_period": "Are you sure you want to save the winning period",
    "confirm__winner_period_message": "When you save the winning period, it will not be modified or deleted."
  },
  "filter": {
    "start_date": "From",
    "end_date": "To",
    "start_range": "",
    "end_range": "",
    "status": "Status",
    "active": "Active",
    "disabled": "Disable",
    "gender": "Gender",
    "male": "Male",
    "female": "Female",
    "date": "Created Date",
    "type": "Type",
    "roles": "Roles"
  },
  "periods": {
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "january": "January",
    "february": "February",
    "march": "March",
    "april": "April",
    "may": "May",
    "june": "June",
    "july": "July",
    "august": "August",
    "september": "September",
    "october": "October",
    "november": "November",
    "december": "December"
  }
}