import axios from "axios";
import { useCookies } from "vue3-cookies";
import i18n from "../libs/i18n";
import router from "../router";
import { useAppsStore } from "@/stores/app";

const baseURL = import.meta.env.VITE_API_URL;
const language = import.meta.env.VITE_APP_I18N_LOCALE;
let i = 1;
const axiosInstance = (app) => {
const { cookies } = useCookies();
  let notify = app.config.globalProperties.$wameedNotification;
  app.config.globalProperties.$http = axios.create({
    baseURL: baseURL,
    headers: {
      locale: i18n.global.locale.value,
      "Content-Type": "application/json;  charset=UTF-8",
    },
  });
  let $http = app.config.globalProperties.$http;
  $http.interceptors.request.use((request) => {
    // Update token axios header
    request.headers.locale = i18n.global.locale.value;
    const authToken = request.headers.Authorization;
    if (!authToken) {
      const loginData = cookies.get("login-data");
      if (loginData) {
        request.headers.Authorization = `Bearer ${JSON.parse(loginData)}`;
      }
    }
    return request;
  });

  $http.interceptors.response.use(
    (response) => {
      const appsStore = useAppsStore();

      appsStore.setErrors({});
      return response;
    },
    (error) => {
      switch (error.response.status) {
        case 401:
          var loginData = cookies.get("login-data");
          if (loginData) {
            // todo logout
            if (i == 1){ i++; notify({ title: "401", type: "fail" });}
          }
         
          cookies.remove("login-data");
          cookies.remove("login-data", "/");
          cookies.remove("login-data", "/ar");
          cookies.remove("login-data", "/en");
          router.push({
            name: "login",
            params: {
              lang:  router.currentRoute.value.params.lang,
            },
          });
          break;

        case 400:
          notify({
            title: error.response.data.message ? error.response.data.message : "400",
            type: "fail",
            api:true
          });
          break;
        case 403:
        case 404:
          router.push({
            name: "not-found",
            params: {
              lang: router.currentRoute.value.params.lang,
            },
          });
          break;
        case 422:
          const appsStore = useAppsStore();

          appsStore.setErrors(error.response.data.data);

          notify({
            title: error.response.data.message,
            type: "fail",
            api:true
          });
          break;
        default:
          notify({
            title: "500",
            type: "fail",
            api:true
          });
      }
      //handel errors here
      return Promise.reject(error);
    }
  );
  return app.config.globalProperties.$http;
};
export default axiosInstance;
