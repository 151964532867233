{
  "wameed_notifications": {
    "success": {
      "information_saved": "تم حفظ البيانات بنجاح",
      "password_changed": "تم حفظ البيانات بنجاح",
      "password_sent": "تم حفظ البيانات بنجاح"
    },
    "fail": {
      "01_01": "لا يوجد حساب مسجل بهذا البريد الإلكتروني.",
      "01_02": "كلمة المرور غير صحيحة.",
      "01_03": "يوجد حساب مسجل بهذا البريد الإلكتروني.",
      "01_04": "رمز مميز غير صالح.",
      "01_05": "كلمة المرور القديمة غير صحيحة.",
      "01_06": "انتهت الجلسة",
      "01_07": "تاكد من صحة الرمز",
      "01_08": "تم حظر هذا المستخدم",
      "01_09": "يوجد حساب مسجل بهذا الرقم.",
      "error": "طلب غير صالح",
      "400": "رمز التحقق غير صالح",
      "401": "انتهت الجلسة",
      "404": " غير موجود",
      "403": "لا يوجد لديك صلاحية.",
      "422": "تأكد من صحة الإدخال.",
      "500": "طلب غير صالح",
      "save": "توجد مشكلة في الحفظ",
      "imageError": "No more than 3 photos can be added",
      "toggle": "هناك مشكله في تغيير الحاله",
      "toggle_role": "لا تستطيع تغيير حالة هذه الصلاحية",
      "update_role": "لا تستطيع تعديل هذه الصلاحية",
      "delete_role": "لا تستطيع حذف هذه الصلاحية"
    }
  }
}