{
  "header": {
    "notifications": "الإشعارات",
    "show_all_notifications": "عرض كل الإشعارات",
    "change_language": "تغيير اللغة"
  },
  "sidebar": {
    "home": "الرئيسية",
    "matches": "المباريات",
    "championships": "البطولات",
    "teams": "الفرق",
    "users": "المستخدمين",
    "advertisements": "الإعلانات",
    "notifications": "الإشعارات",
    "app_content": "محتوى التطبيق",
    "settings": "الاعدادات",
    "platform": "إعدادات المنصة",
    "system-roles": "الصلاحيات",
    "system-users": "مستخدمي النظام",
    "user_profile": "إعدادات الحساب الشخصي",
    "winners":"الفائزين",
    "subscribers":"مشتركي premium",
    "groups":"الدوريات الخاصة"
  },
  "footer": {
    "all_rights_reserved": "جميع الحقوق محفوظة لمنصة",
    "platform":"Go All Fanz",
    "made_by": "صنع في",
    "wameed": "وميض"
  }
}