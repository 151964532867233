import main from "./main.json";
import common from "./common.json";
import authentication from "./authentication.json";
import layout from "./main-layout.json";
const en = {
  ...main,
  ...common,
  ...authentication,
  ...layout,
};
export default en;
