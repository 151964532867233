{
  "common": {
    "ar": "العربية",
    "en": "Engilsh",
    "new": "جديد",
    "order_by": "ترتيب حسب",
    "newest": "الاحدث اولا",
    "older": "الاقدم اولا",
    "alphabetical": "ابجديا أ-ي",
    "show_results": "إظهار النتائج من",
    "show_details": "عرض التفاصيل",
    "filter_data": "فلترة العناصر",
    "search": "ابحث عن البيانات",
    "back": "رجوع",
    "main_information": "البيانات الأساسية",
    "social_information": "مواقع التواصل الإجتماعي",
    "upload": "إضافة",
    "optional": "إختياري",
    "match":"مباراة",
    "team":"نادي",
    "goal":"هدف",
    "point":"نقطة",
    "winners":"فائزين",
    "predictions":"التوقعات",
    "members":"أعضاء",
    "no_results":"لا توجد نتائج",
    "crop_image":"تحرير الصورة",
    "image_preview":"معاينة النتيجة"
  },
  "actions": {
    "cancel": "الغاء",
    "send": "ارسال",
    "save": "حفظ",
    "add": "إضافة",
    "save_updates": "حفظ التعديلات",
    "show_all": "عرض الكل",
    "resend": "إعادة الإرسال",
    "reset": "إعادة تعيين",
    "apply": "تطبيق",
    "confirm": "تاكيد",
    "view": "عرض التفاصيل",
    "details": "عرض التفاصيل",
    "info": "عرض التفاصيل",
    "update": "تعديل البيانات",
    "alter": "تعديل البيانات",
    "activate": "تفعيل",
    "disable": "تعطيل",
    "block": "حظر",
    "delete":"حذف",
    "edit_points":"تعديل النقاط",
    "stop":"إيقاف",
    "match_result":"إضافة نتيجة المباراة",
    "stop_prediction":"إيقاف التوقع",
    "disable_ads": "إيقاف الإعلانات",
    "activate_ads": "تفعيل الإعلانات"
  },
  "no_data": {
    "no_items": "لا يوجد أي عناصر",
    "no_item_message": "المعذرة لم تقم بإضافة أي عناصر حتى الان",
    "no_data": "لا توجد أي بيانات",
    "no_data_message": "لم يتم تقدم أي بيانات حتى الان",
    "page_notfound": "عذراً ،، هذه الصفحة غير متوفرة",
    "page_notfound_message": " المعذرة، هذه الصفحة غير موجودة، قد تكون حذفت، أو نقلت لمكان جديد.",
    "backhome": "العودة للرئيسية",
    "internet_connection": "هناك مشكلة في الإتصال",
    "inernet_connection_message": "يرجى التحقق من اتصالك في الإنترنت و إعادة تحديث الصفحة",
    "refresh": "تحديث",
    "no_notifications": "لا يوجد أي اشعارات",
    "no_notifications_message": "المعذرة لم يتم إرسال أي اشعارات حتى الان",
    "no_more_results": "لا يوجد بيانات اخرى!",
    "no_permissions": "لا توجد لديك صلاحية",
    "no_permissions_message": "المعذرة لا توجد لديك صلاحية لعرض المحتوى"
  },
  "forms": {
    "search": {
      "label": "ابحث"
    },
    "phone_number": {
      "label": "رقم الهاتف"
    },
    "email": {
      "label": "البريد الإلكتروني"
    },
    "password": {
      "label": "كلمة المرور"
    },
    "password_confirmation": {
      "label": "تأكيد كلمة المرور"
    },
    "new_password": {
      "label": "كلمة المرور الجديدة"
    },
    "old_password": {
      "label": "كلمة المرور الحالية"
    },
    "confirm_new_password": {
      "label": "تأكيد كلمة المرور الجديدة"
    },
    "notification_name": {
      "label": "إسم الإشعار"
    },
    "notification_title": {
      "label": "عنوان الإشعار"
    },
    "notification_image": {
      "label": "صورة الإشعار"
    },
    "redirect": {
      "label": "التوجيه"
    },
    "notification_content": {
      "label": "محتوى الإشعار"
    },
    "ads_title": {
      "label": "اسم الاعلان"
    },
    "ads_image": {
      "label": "صورة الاعلان"
    },
    "platform_image": {
      "label": "شعار المنصة"
    },
    "platform_name": {
      "label": "اسم المنصة"
    },
    "platform_description": {
      "label": "وصف المنصة"
    },
    "facebook": {
      "label": "فيسبوك"
    },
    "instagram": {
      "label": "إنستجرام"
    },
    "twitter": {
      "label": "تويتر"
    },
    "youtube": {
      "label": "يوتيوب"
    },
    "tiktok": {
      "label": "تيك توك"
    },
    "role": {
      "label": "المسمى",
      "placeholder": "حدد مسمى الصلاحية"
    },
    "role_name": {
      "label": "الاسم",
      "placeholder": "اكتب اسم الصلاحية"
    },
    "user_name": {
      "label": "اسم المستخدم"
    },
    "profile_image": {
      "label": "الصورة الشخصية",
      "placeholder": "إرفاق صورة"
    },
    "profile_images": {
      "label": "صور البروفايل",
      "placeholder": "إرفاق صور"
    },
    "full_name": {
      "label": "الاسم الكامل"
    },
    "message_title": {
      "label": "عنوان الرسالة"
    },
    "message_content": {
      "label": "نص الرسالة"
    },
    "contact_email": {
      "label": "بريد التواصل"
    },
    "support_email": {
      "label": "بريد الدعم"
    },
    "verification_code": {
      "label": "رمز التحقق"
    },
    "other_phone_number": {
      "label": "رقم هاتف اخر"
    },
    "content":{
      "label": "المحتوى"

    },
    "page_title":{
      "label": "عنوان الصفحة"

    },
    "question":{
      "label": "السؤال"
    },
    "answer":{
      "label": "الإجابة"
    },
    "team_image":{
      "label": "شعار الفريق"
    },
    "team_name":{
      "label": "اسم الفريق"
    },
    "championship_image":{
      "label": "شعار البطولة"
    },
    "championship_name":{
      "label": "اسم البطولة"
    },
    "start_date":{
      "label": "تاريخ البدء"
    },
    "end_date":{
      "label": "تاريخ الإنتهاء"
    },
    "joined_teams":{
      "label": "الفرق المشاركة"
    },
    "championship":{
      "label": "البطولة"
    },
    "team_a":{
      "label":"الفريق الأول"
    },
    "team_b":{
      "label": "الفريق الثاني"
    },
    "match_type":{
      "label": "نوع المباراة"
    },
    "match_date":{
      "label": "الوقت والتاريخ"
    },
    "team_a_score":{
      "label":"نتيجة الفريق الأول"
    },
    "team_b_score":{
      "label": "نتيجة الفريق الثاني"
    },
    "period_type":{
      "label":  "نوع الفائزين"
    },
    "period_name":{
      "label":"اسم الفترة"
    },
    "winners":{
      "label":"الفائزين"
    },
    "country":{
      "label":"الدولة"
    },
    "gender":{
      "label":"الجنس"
    },
    "birth_date":{
      "label":"تاريخ الميلاد"
    },
    "android_code":{
      "label":"كود منصة أندرويد"
    },
    "ios_code":{
      "label":"كود منصة آبل"
    },
    "number_of_elements":{
      "label":"عدد العناصر"
    },
    "number_of_predictions":{
      "label":"عدد التوقعات"
    },
    "winners_type":{
      "label":"فئة الفائزين"
    },
    "period":{
      "label":"الفترة"
    }
  },
  "modal": {
    "logout": "أنت على وشك تسجيل الخروج",
    "logout_btn": "تسجيل الخروج",
    "logout_message": "عند تسجيل خروجك لن تتمكن من الدخول للوحة التحكم إلا بكلمة المرور , هل أنت متأكد من ذلك",
    "confirm_updating_profile": "هل تريد تحديث بياناتك",
    "confirm_updating_profile_message": "سيتم تغيير بياناتك للمدخلات الجديدة التي قمت بإضافتها",
    "delete_title_advertisements": "أنت على وشك حذ ف هذا الإعلان",
    "delete_subtitle_advertisements": "أنت على وشك حذف الإعلان ,سيؤدي ذلك إلى حذف الإعلان بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_system_roles": "أنت على وشك حذ ف هذه الصلاحية",
    "delete_subtitle_system_roles": "أنت على وشك حذف الصلاحية ,سيؤدي ذلك إلى حذف الصلاحية بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_system_users": "أنت على وشك حذف هذا المستخدم",
    "delete_subtitle_system_users": "أنت على وشك حذف المستخدم ,سيؤدي ذلك إلى حذف المستخدم بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "send_notification": "إرسال الإشعار",
    "send_notification_subtitle": "عند الموافقه سيتم ارسال الاشعار لجميع المستخدمين ولن تستطيع التراجع عن هذا الاشعار",
    "delete_title_faq": "أنت على وشك حذف هذا السؤال",
    "delete_subtitle_faq": "أنت على وشك حذف السؤال ,سيؤدي ذلك إلى حذف السؤال بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "disable_title_teams": "هل أنت متأكد من أنك تريد التعطيل؟",
    "disable_subtitle_teams": "عند تعطيل الفريق لن يتم عرض هذا الفريق في المباريات القادمة",
    "disable_title_championships": "هل أنت متأكد من أنك تريد التعطيل؟",
    "disable_subtitle_championships": "عند تعطيل البطولة لن يتم عرضها في المباريات القادمة",
    "disable_title_matches": "هل أنت متأكد من أنك تريد تعطيل المباراة؟",
    "disable_subtitle_matches": "عند تعطيل المباراة سيتم إلغاء جميع التوقعات وإخفاءاها من التطبيق",
    "stop_title_matches": "هل أنت متأكد من أنك تريد إيقاف التوقعات؟",
    "stop_subtitle_matches": "عند إيقاف التوقعات لن يستطيع المستخدمين التوقع , ولن تستطيع إعادة تفعيل التوقعات.",
    "disable_title_users": "هل أنت متأكد من أنك تريد حظر المستخدم؟",
    "disable_subtitle_users": "عند حظر المستخدم؟ لن يستطيع تسجيل الدخول في التطبيق ولن يضهر في اي نتائج",
    "disable_title_google_ads": "هل أنت متأكد من أنك تريد إيقاف إعلانات جوجل؟",
    "disable_subtitle_google_ads": "عند إيقاف إعلانات جوجل سيتم إخفائها من التطبيق ولن تظهر حتى تعيد تفعيلها ",
    "confirm_match_result": "نتيجة المبارة   {team_a} : {team_b} ",
    "confirm__match_result_message": "لن تتمكن تعديل نتيجة المبارة عند التاكيد",
    "confirm_winner_period": "هل أنت متأكد من أنك تريد حفظ فترة الفوز",
    "confirm__winner_period_message": "عند حفظ فترة الفوز لن يتم تعديلها او حذفها "
  },
  "filter": {
    "start_date": "من",
    "end_date": "الى",
    "start_range": "",
    "end_range": "",
    "status": "الحالة",
    "active": "مفعله",
    "disabled": "معطلة",
    "gender": "النوع",
    "male": "ذكر",
    "female": "انثى",
    "date": "تاريخ الاضافة",
    "type": "النوع",
    "roles": "الصلاحيات"
  },
  "periods": {
    "monday": "الاثنين",
    "tuesday": "الثلاثاء",
    "wednesday": "الأربعاء",
    "thursday": "الخميس",
    "friday": "الجمعة",
    "saturday": "السبت",
    "sunday": "الأحد",
    "january": "يناير",
    "february": "فبراير ",
    "march": "مارس",
    "april": "أبريل",
    "may": "مايو",
    "june": "يونيو",
    "july": "يوليو",
    "august": "أغسطس",
    "september": "سبتمبر",
    "october": "أكتوبر",
    "november": "نوفمبر",
    "december": "ديسمبر"
  }
}