import { registerToast, newNotification } from "wameed-ui";
import i18n from "@/libs/i18n";
const wameedNotifications = (app) => {
  registerToast(app);

  const { t } = i18n.global;
  return (app.config.globalProperties.$wameedNotification = (data) => {
    newNotification({
      title: data.api? data.title: t("wameed_notifications." + data.type + "." + data.title),
      type: data.type,
    });
  });
};
export default wameedNotifications;
